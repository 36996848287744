[].forEach.call(document.getElementsByClassName("iframe-lightbox-link"), function(el) {
	el.lightbox = new IframeLightbox(el);
});

$(document).ready(function () {
    showSpinnerWhileIFrameLoads('FGSiFrame');
    showSpinnerWhileIFrameLoads('FloodAreaMapiFrame');
});

function showSpinnerWhileIFrameLoads(iFrameID) {
    var iframe = $('#'+iFrameID);
    var iframeSpinnerID = iFrameID+'Spinner';
    if (iframe.length) {
        $(iframe).before('<div id="'+iframeSpinnerID+'" class="w-100 h-80 d-flex justify-content-center align-items-center"><i class="fa fa-duotone fa-circle-notch fa-spin fa-3x fa-fw"></i></div>');
        $(iframe).on('load', function() {
            document.getElementById(iframeSpinnerID).classList.add("d-none");
            document.getElementById(iFrameID+'Button').classList.remove('d-none');
            this.classList.remove("d-none");
        });
    }
}


